/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {
  //class swap function
  //it is important to get the element with queryselector all for the click but only query selector for the target
  function classSwap(element, classname, target) {
    if (element) {
      for (var i = 0; i < element.length; i++) {
        element[i].addEventListener("click", function () {
          target.classList.toggle(classname);
        });
      }
    }
  }

  //scroll on click
  function scrollOnClick(item, offset) {
    //offset will be incase there is a bar at the top
    offset = offset || undefined;
    if (item.length) {
      item.click(function (e) {
        e.preventDefault();
        var href = $(this).attr("href");
        var target = $(href);
        var scrollAmount;
        if (offset) {
          scrollAmount = target.offset().top - offset.offsetHeight;
        } else {
          scrollAmount = target.offset().top;
        }
        $("html, body").animate(
          {
            scrollTop: scrollAmount,
          },
          500
        );
        return false;
      });
    }
  }

  //equal height function
  function equaHeight(element) {
    $element = element;
    $element.css("height", "auto");
    height = 0;
    $element.each(function () {
      var theight = $(this).outerHeight();
      if (height < theight) {
        height = theight;
      }
    });
    $element.css({ height: height + "px" });
  }

  //get post data function
  //the amount of posts per page and the offset due to the first 5 posts at the start
  //the offset needs to be constantly tracked to keep the posts correct
  var n = 17;
  var postsPer = 12;

  //if it needs a cat name put it in otherwise leave it blank
  function getDataWithOffset(catName, cat) {
    var category_name, tag;
    if (cat) {
      category_name = catName || "";
    } else {
      tag = catName || "";
    }
    //get the needed dom elements
    var $body = $("body");
    var loader = $("#js-loader");
    if (
      $(document).height() - $(window).height() - $(window).scrollTop() <
        1000 &&
      !$body.hasClass("disable-load")
    ) {
      //give the cached dom elements a new class to change content
      $body.addClass("disable-load");
      loader.addClass("active");
      //make the request
      if (cat) {
        $.ajax({
          url: "//" + window.location.host + "/wp/wp-admin/admin-ajax.php",
          type: "post",
          data: {
            action: "load_data_with_offset",
            offset: n,
            //this will go through blank if no category passed and then discarded at the server end.
            category_name: category_name,
          },
          success: function (html) {
            if (html === "") {
              loader.removeClass("active");
              $body.removeClass("disable-load");
              window.removeEventListener("scroll", getDataWithOffset);
              return;
            }
            //remove the spinner
            loader.removeClass("active");
            //build the html up
            var posts = '<div class="row"><div class="load-in">';
            posts += html;
            posts += "</div></div>";
            n += postsPer;
            $(".load__container").append(posts);
            //allow for loading again
            $body.removeClass("disable-load");
          },
        });
      } else {
        $.ajax({
          url: "//" + window.location.host + "/wp/wp-admin/admin-ajax.php",
          type: "post",
          data: {
            action: "load_data_with_offset",
            offset: n,
            //this will go through blank if no category passed and then discarded at the server end.
            tag: tag,
          },
          success: function (html) {
            if (html === "") {
              loader.removeClass("active");
              $body.removeClass("disable-load");
              window.removeEventListener("scroll", getDataWithOffset);
              return;
            }
            //remove the spinner
            loader.removeClass("active");
            //build the html up
            var posts = '<div class="row"><div class="load-in">';
            posts += html;
            posts += "</div></div>";
            n += postsPer;
            $(".load__container").append(posts);
            //allow for loading again
            $body.removeClass("disable-load");
          },
        });
      }
    }
  }

  //if it needs a cat name put it in otherwise leave it blank
  function getDataWithOffsetVideo(catName, cat) {
    var category_name, tag;
    if (cat) {
      category_name = catName || "";
    } else {
      tag = catName || "";
    }
    //get the needed dom elements
    var $body = $("body.page-template-template-video");
    var loader = $("#js-loader");
    if (
      $(document).height() - $(window).height() - $(window).scrollTop() <
        1000 &&
      !$body.hasClass("disable-load")
    ) {
      //give the cached dom elements a new class to change content
      $body.addClass("disable-load");
      loader.addClass("active");
      //make the request
      if (cat) {
        $.ajax({
          url: "//" + window.location.host + "/wp/wp-admin/admin-ajax.php",
          type: "post",
          data: {
            action: "load_data_with_offset_video",
            offset: n,
            //this will go through blank if no category passed and then discarded at the server end.
            category_name: category_name,
          },
          success: function (html) {
            if (html === "") {
              loader.removeClass("active");
              $body.removeClass("disable-load");
              window.removeEventListener("scroll", getDataWithOffsetVideo);
              return;
            }
            //remove the spinner
            loader.removeClass("active");
            //build the html up
            var posts = '<div class="row"><div class="load-in">';
            posts += html;
            posts += "</div></div>";
            n += postsPer;
            $(".load__container").append(posts);
            //allow for loading again
            $body.removeClass("disable-load");
          },
        });
      } else {
        $.ajax({
          url: "//" + window.location.host + "/wp/wp-admin/admin-ajax.php",
          type: "post",
          data: {
            action: "load_data_with_offset_video",
            offset: n,
            //this will go through blank if no category passed and then discarded at the server end.
            tag: tag,
          },
          success: function (html) {
            if (html === "") {
              loader.removeClass("active");
              $body.removeClass("disable-load");
              window.removeEventListener("scroll", getDataWithOffsetVideo);
              return;
            }
            //remove the spinner
            loader.removeClass("active");
            //build the html up
            var posts = '<div class="row"><div class="load-in">';
            posts += html;
            posts += "</div></div>";
            n += postsPer;
            $(".load__container").append(posts);
            //allow for loading again
            $body.removeClass("disable-load");
          },
        });
      }
    }
  }

  var page = 2;

  function getDataWithPage(catName, cat, postType) {
    var category_name, tag;
    if (cat) {
      category_name = catName;
    } else {
      tag = catName;
    }
    var postType = postType || "post";
    //get the needed dom elements
    var $body = $("body");
    var loader = $("#js-loader");
    if (
      $(document).height() - $(window).height() - $(window).scrollTop() <
        1000 &&
      !$body.hasClass("disable-load")
    ) {
      //give the cached dom elements a new class to change content
      $body.addClass("disable-load");
      loader.addClass("active");
      //make the request
      if (cat) {
        $.ajax({
          url: "//" + window.location.host + "/wp/wp-admin/admin-ajax.php",
          type: "post",
          data: {
            action: "load_data_with_page",
            paged: page,
            //this will go through blank if no category passed and then discarded at the server end.
            category_name: category_name,
            post_type: postType,
          },
          success: function (html) {
            //if html returned empty prevent any further requests
            if (html === "") {
              loader.removeClass("active");
              $body.removeClass("disable-load");
              window.removeEventListener("scroll", getDataWithPage);
              return;
            }
            //remove the spinner
            loader.removeClass("active");
            //build the html up
            var posts = '<div class="row"><div class="load-in">';
            posts += html;
            posts += "</div></div>";
            page++;
            $(".load__container").append(posts);
            //allow for loading again
            $body.removeClass("disable-load");
          },
        });
      } else {
        $.ajax({
          url: "//" + window.location.host + "/wp/wp-admin/admin-ajax.php",
          type: "post",
          data: {
            action: "load_data_with_page",
            paged: page,
            //this will go through blank if no category passed and then discarded at the server end.
            tag: tag,
            post_type: postType,
          },
          success: function (html) {
            //if html returned empty prevent any further requests
            if (html === "") {
              loader.removeClass("active");
              $body.removeClass("disable-load");
              window.removeEventListener("scroll", getDataWithPage);
              return;
            }
            //remove the spinner
            loader.removeClass("active");
            //build the html up
            var posts = '<div class="row"><div class="load-in">';
            posts += html;
            posts += "</div></div>";
            page++;
            $(".load__container").append(posts);
            //allow for loading again
            $body.removeClass("disable-load");
          },
        });
      }
    }
  }

  function updateMiniCart(animate) {
    var cartCount = 0;
    if (getCookie("products")) {
      cartCount = JSON.parse(getCookie("products")).length;
    }

    $(".cartCount").text(cartCount);

    if (cartCount > 0) {
      $("li.cart").css({ display: "inline" });
      $("div.cart").css({ display: "inline" });
      $(".cart_svg_1").css({ fill: "#ffffff" });

      if (animate) {
        $("html, body").animate(
          {
            scrollTop: 0,
          },
          500
        );
        $(".icon--cart, .cartCount").addTemporaryClass("animated bounce", 1000);
      }
    } else {
      $(".cart_svg_1").css({ fill: "none" });
    }
  }

  function updateHeaderAuthStatus() {
    $.ajax({
      url: myDdjtUrl + "/logged-in",
      type: "get",
      dataType: "json",
      xhrFields: {
        withCredentials: true,
      },
      success: function (data) {
        if (data.loggedin) {
          $(".nav--sign-up > ul").removeClass("signed-out");
          $("body").removeClass("not-logged-in").addClass("logged-in");

          $(".nav--sign-up .author__avatar img")
            .removeAttr("srcset")
            .attr("src", data.avatar);
          //$('.nav--sign-up .first-name').html(data.user.first_name);
          $(".nav--sign-up .first-name").html("");
          //$('.nav--sign-up .last-name').html(data.user.last_name);
          $(".nav--sign-up .last-name").html("");

          $(".nav--sign-up .not-logged").hide();
          $(".nav--sign-up .logged").css("display", "inline-block");
        } else {
          $(".nav--sign-up > ul").addClass("signed-out");
          $("body").removeClass("logged-in").addClass("not-logged-in");

          $(".nav--sign-up .logged").hide();
          $(".nav--sign-up .not-logged").show();
        }
        $(".nav--sign-up").show();
      },
    });
  }

  function fireAnalytics() {
    $.ajax({
      url: myDdjtUrl + "/a/collect",
      type: "get",
      dataType: "json",
      xhrFields: {
        withCredentials: true,
      },
      data: {
        t: topics,
        p: window.location.href,
      },
    });
  }

  function getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    common: {
      init: function () {
        $(".sales-banner .close").click(function () {
          // for the new mobile join banner
          if ($(this).closest(".sales-banner").hasClass("mobile-join-banner")) {
            document.cookie = "hide_mobile_join_banner=true";
          } else {
            document.cookie = "hide_sales_banner=true";
          }

          $(".sales-banner").hide();
        });

        //check the hash for join and redirect if it is
        if (window.location.hash === "#join") {
          window.location.href = "https://www.digitaldjtips.com/new-sign-up";
        }

        // JavaScript to be fired on all pages
        updateMiniCart(false);

        // Update header if logged in or not
        updateHeaderAuthStatus();

        fireAnalytics();

        //navigation toggle
        var html = document.querySelector("html");
        var nav = document.querySelectorAll(".js-nav-toggle");
        classSwap(nav, "nav-open", html);

        // $('js-nav-toggle').click(function(){
        //     alert('nav toggle clicked0');
        //     $('html').toggleClass('nav-open');
        // });
        //overall blog page, given a class to the page so it will only be fired on that page
        var overallTrigger = $(".js-posts-overall");
        if (overallTrigger.length) {
          window.addEventListener("scroll", function () {
            getDataWithOffset();
          });
        }

        //same for buyers guide page
        var buyersGuide = $("#js-buyers-guide-scroll");
        if (buyersGuide.length) {
          function reviewsWrapFunc() {
            getDataWithPage("", false, "reviews");
          }
          window.addEventListener("scroll", reviewsWrapFunc);
        }

        //category page, cat name put inside a hidden div so it can be obtained and passed through
        if ($("body").hasClass("page-template-template-video") !== true) {
          var cat = $("#js-cat-name");
          if (cat.length) {
            var category_name = cat.data("cat");
            function catWrapFunc() {
              getDataWithOffset(category_name, true);
            }

            window.addEventListener("scroll", catWrapFunc);
          }
        }

        var tag = $("#js-tag-name");
        if (tag.length) {
          var tag_name = tag.data("tag");

          function tagWrapFunc() {
            getDataWithPage(tag_name, false, "post");
          }

          window.addEventListener("scroll", tagWrapFunc);
        }

        //video page scroll trigger load more posts
        if ($("body").hasClass("page-template-template-video")) {
          console.log("video page");
          var cat = $("#js-cat-name-video");
          if (cat.length) {
            var category_name = cat.data("cat");
            function catWrapFuncVideo() {
              getDataWithOffsetVideo(category_name, true);
            }

            window.addEventListener("scroll", catWrapFuncVideo);
          }
        }

        //mix it up to add has support for back button
        if ($("#js-container").length) {
          var containerEl = document.querySelector("#js-container");

          var targetSelector = ".mix";
          var activeHash = "";

          function deserializeHash() {
            var hash = window.location.hash.replace(/^#/g, "");
            var obj = null;
            var groups = [];

            if (!hash) return obj;

            obj = {};
            groups = hash.split("&");

            groups.forEach(function (group) {
              var pair = group.split("=");
              var groupName = pair[0];

              obj[groupName] = pair[1].split(",");
            });

            return obj;
          }
          function serializeGroupsState(groupsState) {
            var output = "";

            for (var key in groupsState) {
              var values = groupsState[key];

              if (!values.length) continue;

              output += key + "=";
              output += values.join(",");
              output += "&";
            }
            output = output.replace(/&$/g, "");

            return output;
          }
          function getGroupsState() {
            // NB: You will need to rename the object keys to match the names of
            // your project's filter groups – these should match those defined
            // in your HTML.
            var fields = document.querySelectorAll("fieldset");

            var groupsState = {};
            for (var i = 0; i < fields.length; i++) {
              if (fields[i].dataset.filterGroup) {
                groupsState[fields[i].dataset.filterGroup] = mixer
                  .getFilterGroupSelectors(fields[i].dataset.filterGroup)
                  .map(getValueFromSelector);
              }
            }
            return groupsState;
          }
          function setHash(state) {
            var selector = state.activeFilter.selector;

            // Construct an object representing the current state of each
            // filter group

            var groupsState = getGroupsState();

            // Create a URL hash string by serializing the groupsState object

            var newHash = "#" + serializeGroupsState(groupsState);

            if (selector === targetSelector && window.location.hash) {
              // Equivalent to filter "all", remove the hash

              activeHash = "";

              history.pushState(null, document.title, window.location.pathname); // or `history.replaceState()`
            } else if (
              newHash !== window.location.hash &&
              selector !== targetSelector
            ) {
              // Change the hash

              activeHash = newHash;

              history.pushState(
                null,
                document.title,
                window.location.pathname + newHash
              ); // or `history.replaceState()`
            }
          }
          var type, platform, software, price;
          function filterMixerByGroupsState(groupsState, animate) {
            if (groupsState.type) {
              type = groupsState && groupsState.type ? groupsState.type : [];
            }
            if (groupsState.platform) {
              platform =
                groupsState && groupsState.platform ? groupsState.platform : [];
            }
            if (groupsState.software) {
              software =
                groupsState && groupsState.software ? groupsState.software : [];
            }
            if (groupsState.price) {
              price = groupsState && groupsState.price ? groupsState.price : [];
            }
            return mixer.parseFilterGroups(animate ? true : false);
          }
          function getValueFromSelector(selector) {
            return selector.replace(/^./, "");
          }
          function getSelectorFromValue(value) {
            return "." + value;
          }
          var groupsState = deserializeHash();
          // Instantiate MixItUp
          var mixer = mixitup(containerEl, {
            multifilter: {
              enable: true,
            },
            animation: {
              effects: "fade",
            },
            callbacks: {
              onMixEnd: setHash, // Call the setHash() method at the end of each operation
            },
          });

          if ($(".page-template-template-courses-list")) {
            var hash = location.hash.replace("#", "");
            var group = hash.substring(0, hash.indexOf("="));
            var filter = hash.split("=").pop();
            if (filter && group) {
              mixer.setFilterGroupSelectors(group, "." + filter);
              mixer.parseFilterGroups();
            }
          }

          if (groupsState) {
            // If a valid groupsState object is present on page load, filter the mixer
            filterMixerByGroupsState(groupsState);
          }

          // Add an "onhashchange" handler to keep the mixer in sync as the user goes
          // back and forward through their history.

          // NB: This may or may not be the desired behavior for your project. If you don't
          // want MixItUp operations to count as individual history items, simply use
          // `history.replaceState()` instead of `history.pushState()` within the `setHash()`
          // function above. In which case this handler would no longer be neccessary.

          window.onhashchange = function () {
            var groupsState = deserializeHash();
            var hash = window.location.hash;

            // Compare new hash with active hash

            if (hash === activeHash) return; // no change

            activeHash = hash;

            filterMixerByGroupsState(groupsState, true);
          };
        }

        //mobile filter for buyers guide reviews
        if ($(".js-mob-filter").length) {
          $(".js-mob-filter").on("click", function () {
            $("html").toggleClass("filter-open");
          });
        }
        //force stupid horizontal scroll on nav
        var horizontalNav = document.querySelectorAll(".nav--scroll");
        if (horizontalNav) {
          for (var i = 0; i < horizontalNav.length; i++) {
            var items = horizontalNav[i].querySelectorAll("li a");
            var width = 0;
            for (var t = 0; t < items.length; t++) {
              width += items[t].offsetWidth;
            }
            horizontalNav[i].style.width = width + "px";
          }
        }

        //copy link to clipboard
        function copyLink(element, hiddenFieldClass) {
          //the permalink is in a hidden input field so it can be copied to the clipboard
          for (var s = 0; s < element.length; s++) {
            element[s].addEventListener("click", function (event) {
              // Select the email link anchor text
              var link = this.querySelector(hiddenFieldClass);
              link.select();
              document.execCommand("copy");
              var copyT = this.querySelector(".copy");
              var copiedT = this.querySelector(".copied");
              copyT.classList.add("hide");
              copyT.classList.remove("show");
              copiedT.classList.add("show");
              copiedT.classList.remove("hide");
              setTimeout(
                function () {
                  copyT.classList.add("show");
                  copyT.classList.remove("hide");
                  copiedT.classList.add("hide");
                  copiedT.classList.remove("show");
                }.bind(this),
                3000
              );
            });
          }
        }

        var copyElement = document.querySelectorAll(".share__icon.copy");
        var copyHiddenField = document.querySelector(".js-copy-link");
        if (copyElement) {
          copyLink(copyElement, ".js-copy-link");
        }
        //slider on courses pages
        var courseSlider = $("#js-course-slider");
        if (courseSlider.length) {
          courseSlider.slick();
        }

        $(".share__icon.facebook").click(function (e) {
          e.preventDefault();
          FB.ui({
            method: "share",
            href: this.href,
          });
        });

        $(".btn-buy-product").click(function (e) {
          e.preventDefault();

          var button = $(this);

          if (button.hasClass("adding")) {
            return;
          }

          if (button.hasClass("added")) {
            window.location.href = ddjtCartUrl + "/multi-checkout";
            return;
          }

          $(".btn-buy-product").addClass("adding");

          var productId = button.data("product-id");

          $.ajax({
            url: ddjtCartUrl + "/api/multi-checkout",
            type: "post",
            xhrFields: {
              withCredentials: true,
            },
            dataType: "json",
            data: {
              products: [productId],
            },
            success: function (products) {
              $(".btn-buy-product").removeClass("adding");
              $(".btn-buy-product").addClass("added");
              window.location.href = ddjtCartUrl + "/multi-checkout";
            },
            error: function (xhr, textStatus, errorThrown) {
              $(".btn-buy-product").removeClass("adding");
              $(".btn-buy-product").addTemporaryClass("failed", 5000);
            },
          });
        });

        //slider on courses overall pages
        var coursesOverallSlider = $(".courses-testimonial>.container");
        if (coursesOverallSlider.length) {
          coursesOverallSlider.slick({
            infinite: true,
            slidesToScroll: 1,
            dots: true,
            arrows: false,
            autoplay: true,
            autoplaySpeed: 10000,
          });
        }

        $('iframe[src*="youtube.com"]').each(function () {
          $(this).wrap('<div class="video-embed"></div>');
        });

        //remove empty paragraphs from boxout
        $(".boxout p:empty").remove();
      },
      finalize: function () {
        // JavaScript to be fired on all pages, after page specific JS is fired

        //single page scrolling
        var $scrollNav = $(".nav--scroll li a.scroll");
        var singlePageOffset = document.querySelector(".scroll-tabs");
        scrollOnClick($scrollNav, singlePageOffset);
        //course page scrolling
        var $courseScroll = $(".js-scroll-tab");
        var courseHeader = document.querySelector("#js-sticky-nav");
        scrollOnClick($courseScroll, courseHeader);

        $(".accordion").click(function () {
          if ($(this).hasClass("active")) {
            $(this).removeClass("active").find(".accordion-body").slideUp();
          } else {
            $(".accordion.active")
              .removeClass("active")
              .find(".accordion-body")
              .slideUp();
            $(this).addClass("active").find(".accordion-body").slideDown();
          }
        });

        $(".search-toggle").click(function () {
          $(".mobile-search.search").fadeToggle();
          $(".search-toggle").fadeToggle();
          $(".search-toggle-icon").toggle();
        });

        $(".mobile-navigation .search").click(function () {
          if (!$("#search-box").hasClass("search__box--open")) {
            $("#search-box").addClass("search__box--open");
          }
        });

        $(".js-search-icon.search__close").click(function () {
          setTimeout(function () {
            $("#search-box").removeClass("search__box--open");
          }, 50);
        });

        var $te = [];
        setTimeout(function () {
          $(".details-tab").each(function () {
            var temp = $(this).offset().top;
            $te.push(temp);
          });
        }, 1000);
        if ($(window).width() < 959) {
          $(".details-tab").removeClass("active");
          $(".details-item").removeClass("active").hide();
        }
        //course page tabs - mobile tab opening - push it to the top of the screen
        $(".details-tab").on("click", function (e) {
          e.preventDefault();
          if ($(window).width() > 959) {
            if (!$(this).hasClass("active")) {
              $(".details-tab").removeClass("active");
              $(this).addClass("active");
              var $target = $(this).attr("data-target");
              $(".details-item").removeClass("active");
              $("." + $target).addClass("active");
            }
          } else {
            var $target = $(this).attr("data-target");
            if ($(this).hasClass("active")) {
              $(this).removeClass("active");
              $("." + $target).slideUp();
              $("." + $target).removeClass("active");
            } else {
              $(".details-tab").removeClass("active");
              $(".details-item").slideUp().removeClass("active");
              $(this).addClass("active");
              $("." + $target)
                .slideDown()
                .addClass("active");
            }
            setTimeout(
              function () {
                if ($target.length) {
                  var arrI = $target.slice(-1) - 1;
                  $("html, body").animate(
                    {
                      scrollTop: $te[arrI],
                    },
                    400
                  );
                  return false;
                }
              }.bind(this),
              0
            );
          }
        });
        //course button
        var $courseBtn = document.querySelector("#js-scroll-tabs") || undefined;
        var $stickyNav = document.querySelector("#js-sticky-nav") || undefined;
        if ($stickyNav) {
          window.addEventListener("scroll", function () {
            if (
              $courseBtn.getBoundingClientRect().top <
              0 - $courseBtn.offsetHeight
            ) {
              $stickyNav.classList.add("active");
            } else {
              $stickyNav.classList.remove("active");
            }
          });
        }

        var productSpecs = document.querySelector("#js-sticky-specs");
        //wait for full page load before executing this.
        window.onload = function () {
          if (productSpecs && window.innerWidth > 1199) {
            //note how far the page has been scrolled
            var distanceScrolled = document.body.scrollTop;
            //note how far the item is from the top of the viewport
            var productSpecsT = productSpecs.getBoundingClientRect();
            //get the top value from the object returned
            var elemViewportOffset = productSpecsT.top - 30;
            //add them together - that is how far from the top of the page it is.
            var productSpecsH = distanceScrolled + elemViewportOffset;

            var footerStart = document.querySelector("footer.primary");
            var reviewContent = document.querySelector("#js-review-content");
            var additionalPad = window
              .getComputedStyle(reviewContent, null)
              .getPropertyValue("padding-bottom");
            additionalPad = Number(
              additionalPad.slice(0, additionalPad.length - 2)
            );

            window.addEventListener("scroll", function () {
              if (window.scrollY > productSpecsH - $courseBtn.offsetHeight) {
                //get full document height
                var _docHeight =
                  document.height !== undefined
                    ? document.height
                    : document.body.offsetHeight;
                //find out the max distance the sidebar should be able to scroll
                var max =
                  _docHeight - (footerStart.offsetHeight + additionalPad);

                //set some general styles for fixing the sidebar
                productSpecs.style.width = productSpecs.offsetWidth + "px";
                productSpecs.style.position = "fixed";

                //if the scroll is greated than the max distance the sidebar can scroll then change the fix from top to bottom
                if (window.scrollY + productSpecs.offsetHeight < max) {
                  //if the scrollbar isnt being used then just set it 20 pixels from the top
                  if ($courseBtn.offsetHeight < 2) {
                    productSpecs.style.top = 20 + "px";
                  } else {
                    productSpecs.style.top = "175px";
                  }
                  productSpecs.style.bottom = "auto";
                } else {
                  //calculate how much bottom is needed based on how much of the footer is showing
                  var btm = window.scrollY + window.innerHeight - max;
                  productSpecs.style.bottom = btm + "px";
                  productSpecs.style.top = "auto";
                }
              } else {
                //if we are higher than the sticky nav can go then set it back to relative .
                productSpecs.style.width = "auto";
                productSpecs.style.top = 0;
                productSpecs.style.position = "relative";
              }
            });
          }
        };

        // search box
        $(".js-search-icon").click(function (e) {
          e.preventDefault();
          if ($("#js-search-box:visible").length) {
            $("#js-search-box").removeClass("search__box--open");
          } else {
            $("#js-search-box").addClass("search__box--open");
          }
        });

        //stop video on modal close

        $("#videoModal").on("hidden.bs.modal", function (e) {
          var modal = e.currentTarget;
          var iframe = modal.querySelector("iframe");
          var video = iframe.src;
          iframe.src = "";
          iframe.src = video;
          // do something…
        });
        var scrollTop = $(".js-scroll-top");
        if (scrollTop) {
          scrollOnClick(scrollTop);
        }

        if ($(".page-template-template-courses-list").length) {
          $(".filter-button").click(function () {
            $(".controls").slideToggle();
            $(".filter-button").toggleClass("show");
          });
        }

        if ($(".dj-courses-template-default").length) {
          $('#course-tabs li a[data-toggle="tab"]').click(function (e) {
            e.preventDefault();
            $("#course-tabs li a").removeClass("active");
            var id = $(this).addClass("active").attr("href");
            $(".tab-content .tab-pane").removeClass("active").hide();
            $(id).addClass("active").show();
          });
        }

        if ($("#wc_show_hide_loggedin_username").length) {
          var login = $(".login-button").attr("href");
          var join = $(".join-button").attr("href");
          $("#wc_show_hide_loggedin_username span").html(
            "Please " +
              '<a href="' +
              login +
              '">Login</a>' +
              " or " +
              '<a href="' +
              join +
              '">Join</a>' +
              " to comment"
          );
        }

        if ($(".slideshow-window").length) {
          function makeid() {
            var text = "";
            var possible =
              "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

            for (var i = 0; i < 20; i++)
              text += possible.charAt(
                Math.floor(Math.random() * possible.length)
              );

            return text;
          }

          $(".slideshow-window").click(function (event) {
            event.preventDefault();
            var src = $(".slideshow-slide:visible").find("img").attr("src");
            var id = makeid();
            $("body").append(
              '<div id="viewer-container-' +
                id +
                '">' +
                '<a class="chocolat-image" href="' +
                src +
                '"></a>' +
                "</div>"
            );
            console.log(src);
            $("#viewer-container-" + id).Chocolat();
            $("#viewer-container-" + id + " .chocolat-image").click();
          });
        }
      },
      // Home page
      home: {
        init: function () {
          // JavaScript to be fired on the home page
        },
        finalize: function () {
          // JavaScript to be fired on the home page, after the init JS
        },
      },
      // About us page, note the change from about-us to about_us.
      about_us: {
        init: function () {
          // JavaScript to be fired on the about us page
        },
      },
    },
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function (func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = funcname === undefined ? "init" : funcname;
      fire = func !== "";
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === "function";

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function () {
      // Fire common init JS
      UTIL.fire("common");

      // Fire page-specific init JS, and then finalize JS
      $.each(
        document.body.className.replace(/-/g, "_").split(/\s+/),
        function (i, classnm) {
          UTIL.fire(classnm);
          UTIL.fire(classnm, "finalize");
        }
      );

      // Fire common finalize JS
      UTIL.fire("common", "finalize");
    },
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

  $(".side-navigation .section-parent > a").click(function (e) {
    console.log("clicked parent");

    $(this).next("ul.sub-menu").slideToggle();
  });

  $(".side-nav-icon").click(function () {
    $(".nav-contents").slideToggle("fast");
  });

  $(document).ready(function ($) {
    $('a[href^="#"]').bind("click.smoothscroll", function (e) {
      e.preventDefault();
      var target = this.hash,
        $target = $(target);

      $("html, body")
        .stop()
        .animate(
          {
            scrollTop: $target.offset().top - 140,
          },
          900,
          "swing",
          function () {
            window.location.hash = target;
          }
        );
    });
  });

  $(document).ready(function () {
    $(".latest-articles-carousel-content").slick({
      slidesToShow: 3,
      slidesToScroll: 1,
      prevArrow: $(".prev"),
      nextArrow: $(".next"),
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    });
  });

  $(".live-lesson-slider").slick({
    centerMode: true,
    centerPadding: "110px",
    slidesToShow: 3,
    arrows: true,
    prevArrow: '<i class="fas fa-chevron-left"></i>',
    nextArrow: '<i class="fas fa-chevron-right"></i>',
    autoplay: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: "40px",
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: "40px",
          slidesToShow: 1,
        },
      },
    ],
  });

  $(".free-resources-slider").slick({
    centerMode: true,
    centerPadding: "110px",
    slidesToShow: 3,
    arrows: true,
    prevArrow: '<i class="fas fa-chevron-left"></i>',
    nextArrow: '<i class="fas fa-chevron-right"></i>',
    autoplay: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: "40px",
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: "40px",
          slidesToShow: 1,
        },
      },
    ],
  });

  $(".home-reviews-slider").slick({
    centerMode: true,
    centerPadding: "110px",
    slidesToShow: 3,
    arrows: true,
    prevArrow: '<i class="fas fa-chevron-left"></i>',
    nextArrow: '<i class="fas fa-chevron-right"></i>',
    autoplay: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: "40px",
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: "40px",
          slidesToShow: 1,
        },
      },
    ],
  });

  // AJAX POST COUNT
  var count_result = $(".count_result");

  if ($(count_result)[0]) {
    var post_id = $(count_result).data("postid"); //Getting post ID from anchor tag

    //Compile the post data to retrieve
    var postData = {
      action: "ajax_page_views",
      post_id: post_id,
      //ajaxurl: '//' + window.location.host + '/wp/wp-admin/admin-ajax.php',
    };

    //Make AJAX post call ( USE POST instead of GET to post data and get data in return)
    $.post(
      "//" + window.location.host + "/wp/wp-admin/admin-ajax.php",
      postData
    ).done(function (result) {
      $(count_result).html(result);
    });
  }
})(jQuery); // Fully reference jQuery after this point.
